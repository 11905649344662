.blurb {
  > div {
    padding: 3em;
  }
  img {
    max-width: 250px;
    max-height: 250px;
  }
}

.jumbotron-x {
  margin-bottom: 0px;
  h1, p {
    /* color: yellow; */
    color: #D6E9F2;
    /* color: #F5F5F5; */
    text-shadow: 0px 0px 2px yellow;
    filter: saturate(50%);
  }
  .img {
    margin-bottom: 0px;
    filter: opacity(0.9) hue-rotate(-45deg);
    background: #000 url("/layouts/landing/img/earth.jpeg") center 25%;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  position: relative;
  padding: 12%;
  .container {
    z-index: 2;
    position: relative;
  }
}

.logo-row {
  text-align: center;
}

.well img {
  max-width: 150px;
  max-height: 75px;
  mix-blend-mode: multiply;
  opacity: 0.5;
  display: inline-block;
  height: auto;
  width: 22%;
}

.nsback {
  background-color: #FF110E;
  padding: 1em;
}

.container-narrow {
  max-width: 1000px;
}

.cloud {
  fill: #334D5C;
}
